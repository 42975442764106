import Helmet from 'react-helmet'
import React, { Component } from 'react'
import Logo from '../images/logo_horizontal.js'
import '../styles/global.scss'

export default class Maintenance extends Component {
    render() {
        return (
            <>
            <Helmet
                title="Maintenance | HomeSpotter"
                description="HomeSpotter is undergoing maintenance"
            >
            <meta name={`robots`} content={`noindex, nofollow`} />
            <html lang="en" />
            </Helmet>
            <div className="header sticky">
                <div className="hs-logo-powered-by">
                    <Logo />
                    <span className="poweredBy">
                        <a href="https://lwolf.com">Powered by Lone Wolf</a>
                    </span>
                </div>
            </div>
            <div className="maintenance col-12">
                <h3>We're getting our house in order</h3>
                <div className="content">
                    <div className="card">
                        <div className="title">
                            HomeSpotter is currently undergoing maintenance
                        </div>

                        <div className="text">
                            Check back in a few minutes. Have questions regarding your existing Boost
                            service? Visit our help center below to find
                            support options.
                        </div>
                        <a
                            className="boost"
                            href="https://lonewolf.my.site.com/s/boost-resources"
                        >
                            Visit our help center
                            <span className="arrow">➜</span>
                        </a>
                    </div>

                </div>
            </div>
        </>            
        )
    }
}
